<template>
  <div>
    <v-app id="ledger5" class="mx-n4">
      <v-app-bar color="white" height="65" flat clipped app>
        <v-app-bar-nav-icon class="d-md-none" @click.stop="drawer = !drawer" />
        <v-toolbar-title style="width: 250px" class="mt-2">
          <span class="primary--text display-1 font-weight-light">Ledger</span
          ><span class="success--text display-1 font-weight-normal">5</span>
        </v-toolbar-title>

        <!-- <v-tabs
          v-model="tab"
          align-with-title
          background-color="transparent"
          slider-color="success"
          slider-size="3"
          centered
        >
          <v-tabs-slider color="success"></v-tabs-slider>

          <v-tab v-for="item in items" :key="item" :to="item.to">
            {{ item.name }}
          </v-tab>
        </v-tabs> -->

        <v-spacer />
        <v-btn
          color="success"
          href="https://www.falcon9.ledger5.com/login"
          target="_self"
          >Login</v-btn
        >
      </v-app-bar>
      <v-main>
        <v-container fluid>
          <v-main>
            <router-view :key="$route.fullPath" class="page" />
          </v-main>

          <v-footer color="advisorLayout" dark fixed style="height: 25px;"
            ><div class="caption mt-n1">
              &copy; 2018 - {{ new Date().getFullYear() }}
            </div>
            <div class="caption advisorLayout--text mt-n1">----</div>
            <div class="caption mt-n1">powered by Ledger</div>
            <div class="caption success--text font-weight-bold mt-n1">5</div>
            <v-spacer />
          </v-footer>
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      drawer: null,
      tab: null,
      items: [
        { name: "Home", to: "/" },
        { name: "About", to: "/about" }
      ]
    };
  }
};
</script>

<style lang="scss">
.page {
  display: flex;
  flex-direction: column;
}
</style>
