import Vue from "vue";
import Vuetify from "vuetify/lib";

//import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi"
  },
  theme: {
    themes: {
      light: {
        primary: "#31465F", // Squid Ink 2
        secondary: "#232F3E", // Squid Ink
        accent: "#55677D", // Squid Ink 3
        error: "#B71C1C", // Red
        info: "#E1E4EA", // light Grey
        success: "#FF9900", // Ember Orange
        warning: "#E6E6E6", // Grey 3

        // Global Ledger5 GUI Colors
        adminLayout: "#007EB9", // Admin Layout Blue
        advisorLayout: "#31465F", // Advisor Layout Dark-Blue (Squid Ink 2)
        clientLayout: "#E1E4EA" // Client Layout Light Grey
      }
    }
  }
});
